import { toggleClass } from './mobileNavigation.js';
import { animate,stagger, inView,timeline } from 'motion';
import Splide from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import baguetteBox from 'baguettebox.js';

document.addEventListener("DOMContentLoaded", function(){
  'use strict';

  //const navigationMenu = new InitNavigationMenu();

  document.body.classList.remove("no-js");

  inView("#texts2", (texts) => {
    animate(texts.target.querySelectorAll('g'),

    {
      clipPath: ["polygon(100% 0%, 100% 100%, 100% 100%, 100% 0% )",
                "polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%)"]
    },
    {
      duration: "2",
      easing: "cubic-bezier(.23,1.09,.44,1)",
      delay: stagger(0.075,{ start: 0.2, from: 'first'})
    });
  });
  // headlines
  inView("#main :is(h1,h2,h3,h4,h5,h6)", (headline) => {
    animate(headline.target,
      {
        transform: ["translateY(-25%)","translateY(0)"],
        opacity: [0,1]
      },
      {
        duration: 2,
        delay: stagger(0.2, {start: 0.2}),
        easing: "cubic-bezier(.23,1.09,.44,1)",
      }
    );
  });

  // hero i
  inView(".hero img",(heroImage) => {
    console.log(heroImage.target);
    animate(heroImage.target,
      {
        transform: ["translateY(-2rem)","translateY(0)"],
        opacity: [0,1]
      },
      {
        duration: 2,
        delay: 0.1,
        easing: "cubic-bezier(.23,1.09,.44,1)",
      }
    );
  })

  document.querySelectorAll('.splide' ).forEach((splideshow) => {
    new Splide( splideshow,{
      perPage: 1,
      perMove: 1,
      autoplay: true,
      autoWidth: true,
      type: 'loop',

      breakpoints: {
        500: {
          perPage: 1,

        },
        720: {
          perPage: 2,

        },
        1200: {
          perPage: 5,
        },
      },
      lazyLoad: 'nearby'} ).mount({ AutoScroll });
  })


  baguetteBox.run('a[data-lightbox]');


});

